import React from "react";

/** @jsx jsx */
import { jsx } from "theme-ui";

export type BaseProps = {
  id?: string;
  sx?: any;
  title?: string;
  children?: React.ReactElement | React.ReactNode;
};

export const BaseSection: React.FC = (props: BaseProps) => {
  return (
    <section
      id={props.id}
      sx={{
        overflowX: "hidden",
        ...props.sectionSx,
      }}
    >
      {props.children}
    </section>
  );
};
