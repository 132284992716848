import React from "react";
import { BaseSection, BaseProps } from "./base";

/** @jsx jsx */
import { jsx, Grid, Link } from "theme-ui";
import Img from "gatsby-image";

type HeroSplashProps = {
  subtitle: string;
  imgSrc: string;
  imgFluid: any;
  cta: React.ReactElement;
};

export const HeroSplashSection: React.FC = (props: HeroSplashProps) => {
  const mode = props.mode;
  const bgColor = "darkBlue";
  const textColor = "white";
  const minHeight = 540;

  return (
    <BaseSection
      id={props.id}
      sectionSx={{
        backgroundColor: bgColor,
        color: textColor,
        // backgroundImage: "url(/images/header-splash.png)",
        backgroundPosition: "bottom",
        paddingTop: "3rem",
        // paddingBottom: "3rem",
        backgroundSize: ["100%", "100%"],
        backgroundRepeat: "no-repeat",
        minHeight: minHeight,
        fontFamily: "Inter",
      }}
    >
      <div className="aw-content">
        <Grid
          gap={[1, 0]}
          columns={["auto", "3fr 2fr"]}
          sx={{ alignItems: ["center", "flex-start"] }}
        >
          <div sx={{ gridRow: [2, 1] }}>
            <h2
              sx={{
                fontWeight: "300",
                fontSize: ["30px", "30px", "36px"],
                // marginBottom: 0,
                lineHeight: "120% !important",
                // letterSpacing: "0.01em",
                color: "#D6D6D6",
              }}
            >
              {props.title}{" "}
              <span sx={{ fontWeight: 500, color: textColor }}>
                <br />
                {props.subtitle}
              </span>
            </h2>
            <p
              sx={{
                fontSize: "20px",
                color: "#E8E8E8", // light mode "#66645E",
                letterSpacing: "-0.02em",
                lineHeight: "133%",
                marginTop: ["12px", "16px", "32px"],
                marginBottom: 0,
                maxWidth: "520px",
                fontWeight: 300,
              }}
            >
              {props.description}
            </p>
            {/* <div
              sx={{
                gridRow: [1, 2],
                marginTop: "12px",
                marginBottom: "24px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                width="20px"
                height="20px"
              />
              <img
                src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                width="20px"
                height="20px"
              />
              <img
                src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                width="20px"
                height="20px"
              />
              <img
                src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                width="20px"
                height="20px"
              />
              <img
                src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
                width="20px"
                height="20px"
              />
              <p
                sx={{
                  fontSize: "12px",
                  lineHeight: "24px",
                  margin: "0 0 0 8px",
                }}
              >
                <Link
                  href={`https://www.google.com/search?ved=1t:65428&hl=en&q=Afterword&ludocid=6984910543917685674&lsig=AB86z5UegKdeFVGPjdSMcp2ZMO8A#lrd=0x808f7f98ee054a9d:0x60ef6321ce181baa,1,,,`}
                  target="_blank"
                  sx={{ color: textColor }}
                >
                  Rated 5.0 stars
                </Link>{" "}
                & trusted by hundreds of families
              </p>
            </div> */}
            <div
              sx={{
                marginTop: "2rem",
                maxWidth: "400px",
              }}
            >
              {props.cta}
            </div>
          </div>

          <div sx={{ marginBottom: [0, "12px"] }}>
            {/* <Img
              position="relative"
              // title={altText}
              // alt={altText}
              fluid={props.imgFluid}
              sx={{
                position: ["relative", "absolute"],
                width: ["100%", "800px"],
                // width: ["66vw", "66vw", "66vw", "60vw", "45vw"],
                // height: ["50vh", "50vh", "60vh", "60vh", "50vh"],
                margin: ["0 auto"],
                borderRadius: "8px",
              }}
            /> */}
            <img
              title="Afterword app logo"
              alt="Afterword app logo"
              sx={{
                position: ["relative", "absolute"],
                width: props.imgWidth || ["100%", "800px"],
                // width: ["66vw", "66vw", "66vw", "60vw", "45vw"],
                // height: ["50vh", "50vh", "60vh", "60vh", "50vh"],
                margin: ["0 auto"],
                borderRadius: "8px",
              }}
              src={props.imgSrc}
            />
          </div>
        </Grid>
      </div>
    </BaseSection>
  );
};
